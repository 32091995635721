@import "./../../../CommonSCSS/mixins.scss"; 

// .quoteoverlay {
//     height: 100%;
//     width: 100%;
//     z-index: 999;
//     position: fixed;
//     top: 0px;
//     left: 0px;

//     .Amaze_Loader {
//         height: 100vh;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//             backdrop-filter: blur(1px);
//     }
// }

.Amaze_Loader {
    position: relative;
    top: 40%;
    left: 45%;
    .MuiCircularProgress-root{
        color: $primary;
        height: 50px !important;
        width: 50px !important;
    }
}