
@import '../../../CommonSCSS/mixins.scss';

.faq {
    margin-bottom: 8px;
    .MuiPaper-root {
        border: 1px solid rgba($black, 0.1);
        @include border-radius(12px);
        background: transparent;
        @include box-shadow(0px 0px 0px transparent);
        margin-bottom: 24px;
        margin-top: 24px;
        &:first-of-type, &:last-of-type {
            @include border-radius(12px);
        }
        &:before {
            display: none;
        }
        &.Mui-expanded {
            @include box-shadow(0px 4px 12px rgba($black, 0.1));
        }
    }
    .MuiAccordionSummary-root {
        padding-left: 24px;
        display: flex;
        gap: 12px;
        @media(max-width:700px) {
            padding-left: 12px;
        }
        &.Mui-expanded {
            min-height: 40px;
        }
        .MuiAccordionSummary-expandIconWrapper {
            color: $black;
            transform: rotate(0deg);
        }
    }

    .MuiAccordionSummary-content {
        margin: 12px 0px;
        &.Mui-expanded {
            margin: 12px 0px;
        }
        p {
            @include fontSize(20px);
            font-family: 'Quicksand', sans-serif;
            font-weight: 600;
            color: rgba($black, 0.8);
            letter-spacing: 0px;
            line-height: 28px;
        }
    }

    .MuiAccordionDetails-root {
        padding: 8px 24px 16px;
        position: relative;
        @media(max-width:700px) {
            padding-left: 12px;
        }
        p {
            @include fontSize(16px);
            color: rgba($black, 0.8);
            line-height: 26px;
        }
    }
}