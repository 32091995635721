@import "../../../CommonSCSS/mixins.scss";

.ctaBtn {
    %primary {
        background: $primary;
        border: 1px solid $primary;
        color: $black;
        @include border-radius(8px);
        font-family: 'Quicksand', sans-serif;
        @include fontSize(16px);
        font-weight: 500;
        text-transform: capitalize;
        @include box-shadow(0px 0px 0px rgba($primary, 0));

        &:hover {
            background: $white;
            @include box-shadow(0px 0px 0px rgba($primary, 0));
        }
    }

    .yellowLgBtn {
        @extend %primary;
        padding: 16px 24px;
        line-height: 20px;
    }

    .yellowMdBtn {
        @extend %primary;
        padding: 12px 20px;
        line-height: 20px;
    }

    .blueMdBtn {
        @extend %primary;
        padding: 12px 20px;
        line-height: 20px;
        background: $secondary;
        border-color: $secondary;
        color: $white;

        &:hover {
            color: $secondary;
        }
    }

    .blueLgBtn {
        @extend %primary;
        padding: 12px 20px;
        line-height: 20px;
        background: $secondary;
        border-color: $secondary;
        color: $white;
        width: 100%;
        display: inline-block;

        &:hover {
            color: $secondary;
        }
    }

    .blackLgBtn {
        @extend %primary;
        background: $black;
        border-color: $black;
        color: $white;
        padding: 16px 24px;
        line-height: 20px;

        &:hover {
            color: $black;
        }
    }

    .whiteLgBtn {
        @extend %primary;
        background: $white;
        border-color: $black;
        color: $black;
        padding: 16px 24px;
        line-height: 20px;

        &:hover {
            background: $black;
            border-color: $black;
            color: $white;
        }
    }

    .whiteSmBtn {
        @extend %primary;
        background: $white;
        border-color: $black;
        color: $black;
        @include fontSize(14px);
        padding: 8px 16px;
        line-height: 16px;

        &:hover {
            background: $black;
            border-color: $black;
            color: $white;
        }
    }


}