@import '../../../CommonSCSS/mixins.scss';

.scheduleDemo {
    .content {
        // background: $white url("../../../../public/images/processbg.svg") no-repeat right bottom/ 50%;
        background: $white;
        // @include border-radius(20px);
        @include position (absolute, $top: 0, $left: 0);
        @include box-shadow (0px 8px 16px rgba($black, 0.3));
        // transform: translate(-50%, -50%);
        padding: 20px 0px;
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: auto;

        &:focus-visible {
            outline: none;
        }

        .closePopup {
            @include border-radius(40px);
            position: absolute;
            right: 20px;
            top: 0px;
            min-width: 20px;
            display: inline-block;
            padding: 0px;
            height: 30px;
            width: 30px;

            &:before {
                background: $black;
                content: "";
                @include position(absolute, $right: 12px, $top: 8px);
                transform: rotate(45deg);
                height: 16px;
                width: 2px;
                transition: transform 0.3s ease-in-out;
            }

            &:after {
                @extend :before;
                transform: rotate(-45deg);
            }

            &:hover {
                &:before {
                    transform: rotate(90deg);
                    background: $secondary;
                }

                &:after {
                    transform: rotate(-90deg);
                    background: $secondary;
                }
            }
        }

        h2 {
            font-weight: 400;
            margin-bottom: 12px;

            span {
                color: $secondary;
                font-weight: 600;
            }
        }

        .leftSideImage {
            width: 100%;
            margin-top: 40px;

            @media(max-width:899px) {
                display: none;
            }
        }

        .selectBox {
            border: 1px solid rgba($black, 0.1);
            @include border-radius(12px);
            padding: 24px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            position: relative;
            min-width: 200px;

            &.active {
                background: $secondary;
                @include box-shadow(0px 10px 20px rgba($secondary, 0.3));
                color: $white;

                .tickIcon {
                    background: $white;
                    @include box-shadow(0px 6px 16px rgba($black, 0.3));

                    &:before,
                    &:after {
                        background: $black;
                        display: inline;
                    }
                }
            }

            .tickIcon {
                background: rgba($black, 0.06);
                @include border-radius(20px);
                @include position(absolute, $right: 12px, $top: 12px);
                height: 24px;
                width: 24px;

                &:before {
                    background: $white;
                    content: '';
                    @include position(absolute, $left: 13px, $top: 7px);
                    transform: rotate(45deg);
                    display: none;
                    height: 10px;
                    width: 1.5px;
                }

                &:after {
                    @extend :before;
                    transform: rotate(-45deg);
                    left: 8px;
                    top: 10px;
                    height: 6px;
                }
            }

            img {
                height: 30px;
            }

            h5 {
                font-weight: 600;
                @include fontSize(20px);
            }

            p {
                opacity: 0.5;
            }

        }

        .ul {
            display: flex;
            gap: 24px;
            margin: 40px 0px;
            flex-wrap: wrap;

            li {
                flex: 1 0 0;
            }
        }

        .step5 {
            .img {
                margin: 0 auto
            }

            .calendly_box {
                width: 100%;
                margin-top: -66px;

                @media(max-width:649px) {
                    margin-top: 0;
                }
                .calendly_head .calendly-inline-widget {
                    min-height: 660px;
                }
            }
        }
    }
}