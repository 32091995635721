// Color Variables
$black: #000000;
$white: #ffffff;

$primary: #FED500;
$secondary: #6458D8;
$grey: #F8F8F8;
$red: #f62525;


/*--------------Mixins------------------*/
@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin border-radius($radius: none) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin appearance() {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@function calculateRem($size) {
    $remSize: $size / 16px;
    @return #{$remSize}rem;
}

@mixin fontSize($size) {
    //font-size: $size;
    font-size: calculateRem($size) !important;
}

@mixin position($position,
    $top: null,
    $right: null,
    $bottom: null,
    $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin abs-position ($top, $right, $bottom, $left) {
    // @include abs-position(10px, 10px, auto, auto);
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}