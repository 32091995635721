@import "../CommonSCSS/mixins.scss";

.MuiModal-root {
  .MuiBackdrop-root {
    background: transparent;
  }
}

.modalWrapper {
  @include position(fixed, $top: 0px, $left: 0px);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 991;
  background: rgba(#000000, 0.2);
  backdrop-filter: blur(4px);
  padding-left: 12px;
  padding-right: 12px;

  .modalContent {
    background: $white;
    @include border-radius(20px);
    @include box-shadow(0px 6px 12px rgba($black, 0.2));
    font-family: "Epilogue", sans-serif;
    margin: 70px auto;
    padding: 30px 18px;
    max-width: 800px;
    width: 100%;
    position: relative;

    @media(max-width:600px) {
      padding: 30px 12px;
    }

    &.smWidth {
      max-width: 600px;
    }

    &.xsWidth {
      max-width: 420px !important;
    }

    &.fullwidth {
      max-width: 100%;
      height: 100%;
      margin: 0;
    }

    h3 {
      color: $secondary;
      @include fontSize(28px);
      font-weight: 700;
      margin-bottom: 8px;

      @media(max-width:600px) {
        @include fontSize(24px);
      }
    }

    p {
      @media(max-width:600px) {
        @include fontSize(13px);
      }
    }

    .closePopup {
      @include border-radius(40px);
      position: absolute;
      min-width: 20px;
      padding: 0px;
      height: 30px;
      width: 30px;
      right: 8px;
      top: 8px;
      // border: 1px solid #F9B300;

      &:before {
        background: $black;
        content: "";
        @include position(absolute, $left: 13px, $top: 6px);
        transform: rotate(45deg);
        height: 16px;
        width: 1.5px;
        transition: transform 0.3s ease-in-out;
      }

      &:after {
        @extend :before;
        transform: rotate(-45deg);
      }

      &:hover {
        &:before {
          transform: rotate(90deg);
          background: $red;
        }

        &:after {
          transform: rotate(-90deg);
          background: $red;
        }
      }
    }

    .tabBox {
      .MuiTabs-indicator {
        display: none;
      }

      .MuiTabs-flexContainer {
        justify-content: center;
        outline: 1px solid $secondary ;
        outline-offset: -1px;
        max-width: 252px;
        margin: auto;
        margin-bottom: 20px;
        border-radius: 30px;

        button {
          color: #000000;
          font-weight: 500;       
          &.active {
            color: #fff;
            background-color: $secondary;
            border-radius: 30px;
          }
        }
      }
    }

    .tabPanel {
      padding: 0;

      .getInTouch {
        .request .blackLgBtn {
          width: 100%;
        }
      }

      .calendly_head {
        height: 532px;

        .calendly-inline-widget {
          height: 532px !important;
        }
      }
    }
  }
}
