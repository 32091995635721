@import '../../../CommonSCSS/mixins.scss';

.caseStudy {
    .innerWrapper {
        overflow-y: auto;
        padding: 24px;
        height: 100%;
        @media(max-width:960px) and (min-width:300px) {
            padding: 12px;
        }
    }
    .content {
        background: url("../../../../public/images/case-study-bg.svg") no-repeat top left -1px;
        background-size: calc(100% + 1.2px);
        max-width:1000px;
        margin: 24px auto;
        position: relative;
        width:100%;
        @media(max-width:860px) and (min-width:300px) {
            // background-size: calc(100% + 24px);
        }
        &:focus-visible {
            outline: none;
        }
        h2{
            @media(max-width:599px){
                @include fontSize(28px);
            }
        }
        .closePopup {
            @include border-radius(40px);
            @include position(absolute, $right: 84px, $top: 72px);
            min-width: 20px;
            display: inline-block;
            padding: 0px;
            height: 30px;
            width: 30px;
    
            &:before {
                background: $black;
                content: "";
                @include position(absolute, $right: 8px, $top: 10px);
                transform: rotate(45deg);
                height: 20px;
                width: 2px;
                transition: transform 0.3s ease-in-out;
            }
    
            &:after {
                @extend :before;
                transform: rotate(-45deg);
            }
    
            &:hover {
                &:before {
                    transform: rotate(90deg);
                    background: $secondary;
                }
    
                &:after {
                    transform: rotate(-90deg);
                    background: $secondary;
                }
            }
            @media(max-width:980px) and (min-width:861px) {
                right:70px;
            }
            @media(max-width:860px) and (min-width:670px) {
                top: 50px;
                right:50px;
            }
            @media(max-width:671px) and (min-width:600px) {
                top: 40px;
                right:46px;
            }
            @media(max-width:601px) and (min-width:300px) {
                top: 12px;
                right:auto;
                left: 12px;
            }
        }
        .pageTitle {
            @media(max-width:860px) and (min-width:600px) {
                padding: 36px 12px;
                h3{
                    padding-top: 0 !important;
                }
            }
            @media(max-width:599px) and (min-width:300px) {
                h3 {
                    @include fontSize(20px);
                    padding-top: 0 !important;
                }
                padding: 28px 12px;
            }
        }
        .innerContent {
            border-Bottom: 4px solid #000;
            border-Left: 4px solid #000;
            border-Right: 4px solid #000;
            @media(max-width:500px) and (min-width:300px) {
                border-width: 1.5px;
                border-top: none;
            }
            @media(max-width:800px) and (min-width:501px) {
                border-width: 3px;
                border-top: none;
            }
        }
        .MuiGrid2-container {
            padding-left: 16px !important;
            padding-right: 16px !important;
        }
        // p {
        //     @media(max-width:960px) and (min-width:300px) {
        //             text-align: justify;
        //         }
        // }
        .greyBox {
            padding: 32px;
            @media(max-width:960px) and (min-width:300px) {
                padding: 20px;
            }
            @media(max-width:599px){
                flex-direction: column;
                .left-prt,.right-prt{
                    width: 100% !important;
                }
            }
        }
    }
}