@import '../../../CommonSCSS/mixins.scss';

.footer {
    background: $grey url("../../../../public/images/footerbg.png") no-repeat top center/ 100%;
    padding-top: 200px;
    padding-bottom: 12px;
    position: relative;

    @media(max-width:600px) {
        padding-top: 70px;
    }

    &:after {
        content: '';
        @include position(absolute, $top: -10px, $left: 0px);
        background: url("../../../../public/images/footericons.svg") no-repeat top center/ 90%;
        width: 100%;
        height: 200px;
        z-index: 91;

        @media(max-width:600px) {
            height: 100px;
        }
    }

    h5 {
        @include fontSize(20px);
        font-weight: 700;
    }

    p {
        @include fontSize(16px);
        font-weight: 400;
    }

    a {
        @include fontSize(16px);
        color: $black;
        font-weight: 500;
        padding: 4px 0px;
        display: inline-block;

        &:hover {
            color: $primary;
        }
    }

    ul {
        margin-top: 24px;

        li {
            margin-bottom: 8px;
        }
    }

    .footer_addr {
        p {
            font-weight: 300;
        }

        span {
            display: flex;
            font-weight: 500;
        }

        .box1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 92px;
        }
    }

    .socialLinks {
        display: flex;
        gap: 20px;
        margin: 0;

        a {
            &:hover{
                transform: rotate(720deg);
                transition: transform 1s ease-out;
            }
            svg {
                width: 34px;
                height: 34px;
            }
        }
    }
}