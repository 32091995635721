@import "../../../CommonSCSS/mixins.scss";

$icons_and_colors: (
    bluebox,
    #CAE1FB),
    (yellowbox, #FCDB9D),
    (cyanbox, #80E4DC),
    (greenbox, #ADDF9C),
    (redbox, #FFA4A4),
    (purplebox, #BBB7F5
);

@for $i from 1 through length($icons_and_colors) {
    $pair: nth($icons_and_colors, $i);
    $icon: nth($pair, 1);
    $color: nth($pair, 2);

    .#{$icon} {
        @include border-radius(12px);
        background: $color;
        padding: 24px;
        min-height: 360px;
    }
}