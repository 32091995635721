@import '../../../CommonSCSS/mixins.scss';

.navbar {
    background: $white;
    border-bottom: 1px solid rgba($black, 0.1);
    @include position(fixed, $top:0px, $left:0px);
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 76px;
    z-index: 991;
    .logo {
        height: 50px;
        margin-right: 50px;
        @media(max-width:992px) {
            height: 40px;
        }
    }
    ul {
        display: inline-flex;
        align-items: center;
        gap: 24px;
        @media(max-width:1400px) {
            gap: 16px;
        }
        li {
            a, button {
                @include fontSize(16px);
                color: $black;
                font-weight: 500;
                padding: 4px 0px;
                display: inline-block;
        
                &:hover {
                    color: $primary;
                }
                @media(max-width:1400px){
                    @include fontSize(14px);
                }
            }
            .dropdown_menu_btn{
                background-color: transparent;
                transition: none !important;
            -webkit-tap-highlight-color: none;
            }
        }
    }
}