@import "../../../CommonSCSS/mixins.scss";

.chooseBox {
    background: $grey;
    @include border-radius(20px);
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 20px;
    position: relative;
    overflow: hidden;
    &:hover, &.active {
        background: #FCDB9D;
        // cursor: none;
    }
    @media(max-width: 600px){
        flex-direction:column;
        text-align: center;
        gap: 12px;
    }
    &:before {
        @include position(absolute, $left:0px, $bottom:0px);
        content: '';
        background: url("../../../../public/images/left-ellipse.svg") no-repeat left bottom/100%;
        height: 140px;
        width: 140px;
    }
    &:after {
        @include position(absolute, $right:0px, $top:0px);
        content: '';
        background: url("../../../../public/images/right-ellipse.svg") no-repeat top right/100%;
        height: 140px;
        width: 140px;
    }
}