@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https: //fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap');
@import "../src/CommonSCSS/mixins.scss";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // -webkit-font-smoothing: antialiased;
  // text-rendering: auto;
  // text-rendering: optimizeSpeed;
  // text-rendering: optimizeLegibility;
  // text-rendering: geometricPrecision;
  // font-synthesis: none;
  // -moz-font-feature-settings: "kern";
  // -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
  margin: 0px;
  padding: 0px;
}

body {
  background-color: #ffffff;
  font-family: 'Quicksand', sans-serif;
  height: 100%;
  margin: 0px;
  padding: 0px;
  @include fontSize(16px);
  font-weight: 400;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  // font-smooth: always;
  // font-smoothing: antialiased;
  // -moz-font-smoothing: antialiased;
  // -webkit-font-smoothing: antialiased;
  // text-size-adjust: none;
  // -webkit-text-size-adjust: none;

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
  }
}


h1 {
  @include fontSize(52px);
  line-height: 65px;
}

h2 {
  @include fontSize(36px);
  line-height: 44px;
  margin-bottom: 12px !important;
  span{
    font-weight: 400;
  }

  @media(max-width:600px) {
    @include fontSize(24px);
    line-height: 36px;
  }
}

h3 {
  @include fontSize(28px);
}

h4 {
  @include fontSize(24px);
  line-height: 30px;
  @media(max-width:600px) {
      @include fontSize(18px);
      line-height: 24px;
    }
}

h5 {
  @include fontSize(20px);
}

p {
  font-family: 'Roboto', sans-serif;
  @include fontSize(16px);
  font-weight: 300;
  line-height: 24px;
}

button {
  text-transform: inherit !important;
  font-family: 'Quicksand', sans-serif !important;
  letter-spacing: 0px !important;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

.MuiGrid2-container {
  max-width: 1400px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.greyBox {
  background: $grey;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* latin-ext */
@font-face {
  font-family: 'Fredericka the Great';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/frederickathegreat/v21/9Bt33CxNwt7aOctW2xjbCstzwVKsIBVV--StxbcVcg.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Fredericka the Great';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/frederickathegreat/v21/9Bt33CxNwt7aOctW2xjbCstzwVKsIBVV--Sjxbc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.mt-40-m{
  @media(max-width:700px){
    margin-top: 40px !important;
  }
}
.mb-40-m{
  @media(max-width:700px){
    margin-bottom: 40px !important;
  }
}
.my-40-m{
  @media(max-width:700px){
    margin-bottom: 40px !important;
    margin-top: 40px !important;
  }
}
.py-40-m{
  @media(max-width:700px){
    padding-bottom: 40px !important;
    padding-top: 40px !important;
  }
}



/* For WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: $secondary;
  border-radius: 6px;
}
