@import "../../CommonSCSS/mixins.scss";


.cms_wrapper {
    padding-top: 104px;

    &.contact_top {
        padding-top: 74px;

        @media(max-width:767px) {
            padding-top: 60px;
        }
    }

    // career page css

    .career_header {
        h1 {
            @include fontSize(52px);
            line-height: 65px;
            font-weight: 400;
            margin-bottom: 24px;

            @media (max-width:768px) {
                @include fontSize(32px);
                line-height: 50px;
            }

            span {
                font-weight: 700;
            }
        }

        p {
            @include fontSize(20px);
            line-height: 25px;
            font-weight: 700;
            margin-bottom: 0px;

            @media (max-width:768px) {
                @include fontSize(15px);
                line-height: 20px;
            }

            span {
                display: block;
            }
        }
    }

    .cv_section {
        min-height: 500px;
        position: relative;

        &:before {
            content: '';
            background: url("../../../public/images/career_img_io.svg") no-repeat center / 100%;
            height: 449px;
            width: 100%;
            max-width: 694px;
            @include position(absolute, $left: 50%, $top: 0px);
            transform: translateX(-50%);
        }

        .share_cv {
            display: flex;
            position: relative;
            z-index: 9;

            @media (max-width:900px) {
                top: -30px;

                &:before {
                    display: none;
                }
            }

            &:before {
                content: '';
                background: url("../../../public/images/arrow_line.svg") no-repeat;
                height: 156px;
                width: 121px;
                @include position(absolute, $bottom: 120px, $left: 60px);
            }


            .ev_bg {
                background: #7165E4;
                @include border-radius(100px);
                padding: 12.5px;
                display: inline-block;
                text-align: center;
                color: $white;
                @include fontSize(20px);
                line-height: 25px;
                margin-right: 12px;
                height: 50px;
                position: relative;

                &:before {
                    content: "";
                    background: #5BDC77;
                    height: 8px;
                    width: 8px;
                    @include position (absolute, $right: 0px, $bottom: 9px);
                    @include border-radius(100%);
                }
            }

            .right_section {
                h5 {
                    @include fontSize(16px);
                    line-height: 24px;
                    font-weight: 300;
                    padding: 12px 20px;
                    @include border-radius(12px);
                    background: $white;
                    box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.10);
                    margin-bottom: 12px;
                    display: inline-block;
                    max-width: 185px;

                    @media (max-width:900px) {
                        @include fontSize(13px);
                        line-height: normal;
                    }
                }

                p {
                    @include fontSize(16px);
                    line-height: 24px;
                    font-weight: 300;
                    padding: 12px 20px;
                    @include border-radius(12px);
                    background: $white;
                    box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.10);
                    margin-bottom: 12px;
                    display: inline-block;
                    max-width: 377px;

                    @media (max-width:900px) {
                        @include fontSize(13px);
                        line-height: normal;
                    }
                }
            }
        }

        .share_section {
            @include border-radius(12px);
            background: $white;
            box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.10);
            padding: 0 20px 20px;
            display: inline-block;
            max-width: 340px;
            text-align: center;
            position: relative;
            z-index: 9;

            @media (max-width:900px) {
                padding: 10px;
                bottom: -30px;
            }

            .MuiTypography-root {
                background: #C2EFB3;
                display: inline-block;
                padding: 10px 16px;
                @include border-radius(8px);

                img {
                    height: 20px;
                }
            }

            p {
                @include fontSize(16px);
                line-height: 24px;
                margin-bottom: 0px;

                @media (max-width:900px) {
                    @include fontSize(13px);
                    line-height: normal;
                }
            }
        }

        .upload_section {
            @include border-radius(12px);
            border: 1px dashed $black;
            background: $white;
            box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.10);
            padding: 15px;
            z-index: 9;
            position: relative;

            svg {
                height: 24px;
                transform: rotate(45deg);

            }

            span {
                @include fontSize(16px);
                line-height: 44px;
                font-weight: 300;
                margin-left: 12px;

                @media(max-width:501px) {
                    line-height: 24px;
                }
            }

            .fileUpload {
                .custom-file-upload {
                    display: inline-block;
                    color: #333333;
                    cursor: pointer;

                    span {
                        margin-right: 10px;
                        display: inline-block;
                    }

                    input {
                        display: none;
                    }
                }
            }
        }
    }

    .apply_section {
        background: #F8F8F8;
        padding: 40px 0;
        min-height: 620px;

        h5 {
            @include fontSize(32px);
            line-height: 40px;
            font-weight: 400;
            margin-bottom: 24px;

            span {
                @include fontSize(52px);
                line-height: 65px;
                font-weight: 700;
            }
        }

        p {
            @include fontSize(16px);
            line-height: 24px;
            font-weight: 300;
            margin-bottom: 40px;

            span {
                display: block;
            }
        }

        .left_part {
            position: relative;
            min-height: 100%;
            min-height: 400px;

            &:before {
                content: '';
                background: url("../../../public/images/apply_img_man.svg") no-repeat;
                height: 400px;
                width: 153px;
                @include position (absolute, $right: 0, $top: 0);

                @media (max-width:900px) {
                    @include position(absolute, $top: 0px, $left: calc(50% - 75px));
                }
            }

            .boxOne {
                @include border-radius(12px);
                background: rgba(255, 255, 255, 0.50);
                backdrop-filter: blur(8px);
                padding: 20px;
                text-align: end;
                display: inline-block;
                max-width: 420px;
                width: 100%;
                @include position(absolute, $top: 240px, $right: 50px);

                @media (max-width:900px) {
                    text-align: start;
                    max-width: 320px;
                    @include position(absolute, $top: 240px, $left: calc(50% - 135px));
                }

                h5 {
                    @include fontSize(18px);
                    line-height: 23px;
                    font-weight: 700;
                    margin-bottom: 4px;
                }

                p {
                    @include fontSize(14px);
                    line-height: 20px;
                    font-weight: 300;
                    margin-bottom: 0px;
                }
            }
        }

        .right_part {
            position: relative;
            min-height: 100%;
            min-height: 400px;

            @media (max-width:900px) {
                min-height: 450px;
            }

            &:before {
                content: '';
                background: url("../../../public/images/apply_img_woman.svg") no-repeat;
                height: 400px;
                width: 215px;
                @include position (absolute, $left: 0, $top: 50px);

                @media (max-width:900px) {
                    @include position(absolute, $top: 0px, $left: calc(50% - 105px));
                }
            }

            .boxOne {
                @include border-radius(12px);
                background: rgba(255, 255, 255, 0.50);
                backdrop-filter: blur(8px);
                padding: 20px;
                display: inline-block;
                max-width: 340px;
                width: 100%;
                @include position(absolute, $top: 10px, $left: 160px);

                @media (max-width:900px) {
                    max-width: 320px;
                    @include position(absolute, $top: 140px, $left: calc(50% - 145px));
                }

                h5 {
                    @include fontSize(18px);
                    line-height: 23px;
                    font-weight: 700;
                    margin-bottom: 4px;
                }

                p {
                    @include fontSize(14px);
                    line-height: 20px;
                    font-weight: 300;
                    margin-bottom: 0px;
                }
            }

            .boxTwo {
                @include border-radius(12px);
                background: rgba(255, 255, 255, 0.50);
                backdrop-filter: blur(8px);
                padding: 20px;
                display: inline-block;
                max-width: 420px;
                width: 100%;
                @include position(absolute, $top: 240px, $left: 50px);

                @media (max-width:900px) {
                    max-width: 320px;
                    @include position(absolute, $top: 280px, $left: calc(50% - 145px));
                }

                h5 {
                    @include fontSize(18px);
                    line-height: 23px;
                    font-weight: 700;
                    margin-bottom: 4px;
                }

                p {
                    @include fontSize(14px);
                    line-height: 20px;
                    font-weight: 300;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .why_work {
        h5 {
            @include fontSize(52px);
            line-height: 65px;
            font-weight: 400;
            margin-bottom: 24px;

            @media (max-width:768px) {
                @include fontSize(32px);
                line-height: 50px;
            }

            span {
                font-weight: 700;
            }
        }

        p {
            @include fontSize(16px);
            line-height: 24px;
            font-weight: 300;
            margin-bottom: 0px;
        }

        .numberBox {
            display: flex;
            flex-direction: column;
            min-width: 270px;
            flex: 1 0 0;
            margin: 0px 30px;

            .heading {
                @include border-radius(12px);
                background: $white;
                @include box-shadow(0px 12px 20px 0px rgba(0, 0, 0, 0.05));
                padding: 24px 40px;
                margin-bottom: 60px;
                position: relative;

                h4 {
                    @include fontSize(20px);
                    font-weight: 500;

                    &:first-letter {
                        @include fontSize(32px);
                        color: $secondary;
                    }
                }

                .numberIcon {
                    @include position(absolute, $left: -30px, $bottom: -30px);
                    background: lighten($secondary, 30%);
                    @include border-radius(40px 40px 0px 40px);
                    @include box-shadow(0px 0px 20px 0px rgba(113, 101, 228, 0.20));
                    @include fontSize(24px);
                    font-weight: 500;
                    padding-top: 10px;
                    text-align: center;
                    color: $secondary;
                    height: 60px;
                    width: 60px;
                }
            }

            p {
                margin-bottom: 12px;
            }
        }
    }


    // about page css

    .about_header {
        background: linear-gradient(180deg, #FFF 0%, #F8F8F8 100%);
        padding: 40px 0;

        h5 {
            @include fontSize(52px);
            line-height: 65px;
            font-weight: 400;
            margin-bottom: 24px;

            span {
                font-weight: 700;
            }

            @media (max-width:768px) {
                @include fontSize(32px);
                line-height: 50px;
            }
        }

        p {
            @include fontSize(16px);
            line-height: 24px;
            font-weight: 300;
            margin-bottom: 40px;

            span {
                display: block;
            }
        }

        img {
            height: 369px;
            display: inline-block;

            @media(max-width:900px) {
                height: 300px;
            }

            @media(max-width:500px) {
                height: 220px;
            }
        }
    }

    .our_mission {
        h5 {
            @include fontSize(36px);
            line-height: 45px;
            font-weight: 600;
            margin-bottom: 12px;

        }

        h6 {
            @include fontSize(20px);
            line-height: 25px;
            font-weight: 700;
            margin-bottom: 12px;
            color: #333333;
        }

        p {
            @include fontSize(16px);
            line-height: 24px;
            font-weight: 300;
            margin-bottom: 0px;
            color: #333333;
        }

        img {
            display: inline-block;
            height: 349px;

            @media(max-width:900px) {
                height: 230px;
            }
        }

    }

    .itWorks_section {
        .works_box {
            @include border-radius(20px);
            background: linear-gradient(81deg, #D5F1EC 0%, #ECDFBE 51.56%, #F6CFDE 100%);
            padding: 40px 28px;

            .fundamental_box {
                h5 {
                    @include fontSize(20px);
                    line-height: 25px;
                    font-weight: 700;
                    margin-bottom: 24px;
                }

                h6 {
                    @include fontSize(36px);
                    line-height: 45px;
                    font-weight: 600;
                    margin-bottom: 24px;
                }

                p {
                    @include fontSize(16px);
                    line-height: 24px;
                    font-weight: 300;
                    margin-bottom: 0px;
                    color: #333333;
                }

            }

            .principle_list {
                display: flex;

                @media (max-width:600px) {
                    flex-wrap: wrap;
                    justify-content: center;
                }

                li {
                    @include border-radius(12px);
                    border: 2px solid #FFF;
                    background: rgba(255, 255, 255, 0.60);
                    backdrop-filter: blur(2px);
                    padding: 24px;
                    margin-right: 24px;

                    @media (max-width: 600px) {
                        min-width: 250px;
                        margin-right: 0px;
                        margin-bottom: 12px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    .contentBox {
                        display: flex;
                        align-items: center;
                        margin-bottom: 12px;

                        img {
                            height: 60px;
                            margin-right: 10px;
                        }

                        h6 {
                            @include fontSize(20px);
                            line-height: 25px;
                            font-weight: 700;
                            margin-bottom: 0px;
                        }
                    }

                    p {
                        @include fontSize(16px);
                        line-height: 24px;
                        font-weight: 300;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }

    .expert_team {
        img {
            display: inline-block;
            height: 336px;

            @media(max-width:900px) {
                height: 230px;
            }
        }

        h5 {
            @include fontSize(20px);
            line-height: 25px;
            font-weight: 700;
            margin-bottom: 24px;

        }

        h6 {
            @include fontSize(36px);
            line-height: 45px;
            font-weight: 600;
            margin-bottom: 24px;

            @media (max-width:768px) {
                @include fontSize(24px);
                line-height: 35px;
            }
        }

        p {
            @include fontSize(16px);
            line-height: 24px;
            font-weight: 300;
            margin-bottom: 24px;
            color: #333333;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    // contatus css

    .contact_header {
        min-height: 1030px;
        padding-top: 70px;
        position: relative;

        &:before {
            content: '';
            background: #7165E4;
            height: 515px;
            width: 100%;
            @include position(absolute, $left: 0, $top: 0);
            z-index: -9;
        }

        &:after {
            content: '';
            background: #7165E4;
            height: 410px;
            width: 100%;
            @include position(absolute, $left: 0, $bottom: 310px);
            z-index: -9;
            transform: skew(93deg);
        }

        h5 {
            @include fontSize(52px);
            line-height: 65px;
            font-weight: 400;
            margin-bottom: 24px;
            color: $white;

            span {
                font-weight: 700;
            }

            @media (max-width:768px) {
                @include fontSize(32px);
                line-height: 50px;
            }
        }

        p {
            @include fontSize(20px);
            line-height: 25px;
            font-weight: 700;
            margin-bottom: 60px;
            color: $white;
        }

        .contact_box {
            @include border-radius(20px);
            background: $white;
            box-shadow: 0px 9px 11px 0px rgba(0, 0, 0, 0.06);
            padding: 80px 20px;

            @media(max-width: 1400px) {
                margin: 0 20px;
            }

            @media(max-width:900px) {
                padding: 40px 20px;
            }

            .cont_leftSection {
                max-width: 590px;
                margin: 0 auto;
                width: 100%;

                .textDesc .MuiInputBase-multiline,
                .searchSelect .MuiAutocomplete-inputRoot {
                    background: #F6F5FF;
                }

                .inputField {

                    label,
                    input,
                    textarea {
                        color: #7165E4;
                    }
                }

                input {
                    @include border-radius(8px);
                    background: #F6F5FF;
                    // border: 1px solid #7165E4;
                }

                fieldset {
                    // background: #F6F5FF;
                    border-color: #7165E4;
                }

            }

            .cont_rightSection {
                width: 500px;
                margin: 0 auto;

                img {
                    height: 250px;
                }

                @media(max-width:900px) {
                    img {
                        height: 180px;
                    }
                }

                .social_sites {
                    margin: 24px auto 0;

                    li {
                        list-style-type: none;
                        margin-bottom: 24px;
                        padding-left: 66px;
                        position: relative;
                        width: 365px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        h6 {
                            @include fontSize(20px);
                            line-height: 25px;
                            font-weight: 700;
                            margin-bottom: 12px;
                            color: $black;
                        }

                        p {
                            @include fontSize(16px);
                            line-height: 24px;
                            font-weight: 300;
                            margin-bottom: 0px;
                            color: $black;
                        }

                        .MuiTypography-root {
                            @include fontSize(16px);
                            line-height: 24px;
                            font-weight: 300;
                            margin-right: 5px;
                            color: $black;
                        }

                        $button-icons: phone, address, social;
                        $k: 0;

                        @each $icons in $button-icons {
                            $bacRightP: $k * 54;
                            $k: $k +1;

                            &.#{$icons} {
                                &:before {
                                    @include position(absolute, $left: 0px, $top: calc(50% - 27px));
                                    content: "";
                                    height: 54px;
                                    width: 54px;
                                    background: url("../../../public/images/social_icon.svg") no-repeat -#{$bacRightP}px 0px /162px;
                                    z-index: 2;
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}