@import "../../CommonSCSS/mixins.scss";

.homeWrapper {
    padding-top: 76px;
    @media(max-width:600px) {
        padding-top: 60px;
    }

    .header {
        background-color: #def2e7;
        animation: hue 8s linear 0s infinite reverse;
        position: relative;
        padding-top: 40px;
        padding-bottom: 40px;
        
        @media(max-width:600px) {
            padding-top: 20px;
        }

        &:before {
            content: '';
            // background: linear-gradient(180deg, #FFF 0%, #F8F8F8 100%);
            background: linear-gradient(180deg, #FFF 0%, #e8fdcc);
            animation: hue 8s linear 1s infinite;
            @include position(absolute, $left: 0px, $top: 0px);
            height: 100%;
            width: 100%;
            z-index: -1;
        }

        @keyframes hue {
            to {
                filter: hue-rotate(1turn);
            }
        }

        h1 {
            font-weight: 400;

            span {
                font-weight: 700;
                color: $secondary;
            }
            @media(max-width:1600px) {
                @include fontSize(42px);
                line-height: 52px;
            }
            @media(max-width:992px){
                @include fontSize(40px);
                line-height: 50px;
            }
            @media(max-width:600px){
                @include fontSize(28px);
                line-height: 36px;
            }
        }

        img {
            // max-width: 820px;
            width: 100%;
            max-height: 412px;
        }

        h6 {
            @include fontSize(20px);
            @media(max-width:992px) {
                @include fontSize(16px);
            }
        }

        .contentSlide {
            .slide {
                position: relative;
                min-height: 250px;
            }

            .show {
                // position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                animation: fade 1.5s ease-in-out;
            }

            .show>div {
                width: 100%;
                height: 100%;
                min-height: 250px;
            }

            @keyframes fade {
                from {
                    opacity: 0.1;
                }

                to {
                    opacity: 1;
                }
            }

            .not-show {
                display: none;
            }
        }
    }

    .testimonials {
        .user {
            background: $white;
            @include border-radius(100px);
            height: 200px;
            width: 200px;
            overflow: hidden;
            margin: 0px auto 40px;
        }

        h5 {
            font-weight: 600;
        }

        h6 {
            @include fontSize(20px);
            @media(max-width:768px) {
                @include fontSize(16px);
            }
        }

        .designnation {
            opacity: 0.5;
            margin-bottom: 40px;
            @media(max-width:768px) {
                margin-bottom: 20px;
            }
        }

        .react-multi-carousel-item {
            padding-left: 120px;
            padding-right: 120px;

            @media(max-width:768px) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        .react-multiple-carousel__arrow {
            background: #E3EDFF;
            z-index: 91;

            &:before {
                color: $black;
            }
        }

        .react-multiple-carousel__arrow--right {
            right: 0px;
        }

        .react-multiple-carousel__arrow--left {
            left: 0px;
        }

    }

    .clientsIndustries {
        .MuiTabPanel-root {
            background: url("../../../public/images/bg_img.svg") no-repeat center center/80%;
            min-height: 240px;
            @media(max-width:700px){
                padding: 12px 0px;
            }

            img {
                width: 100%;
                max-height: 240px;
                margin: 0px auto;
                @media(max-width:600px) {
                    width: 70%;
                }
            }

            h5 {
                font-weight: 600;
                margin-bottom: 12px;
            }
        }

        .MuiTabs-root {
            .MuiTabs-flexContainer {
                gap: 40px;

                @media(max-width:1400px) {
                    overflow-x: scroll;
                    justify-content: flex-start;
                }
                @media(max-width:1024px) {
                    overflow-x: scroll;
                    justify-content: flex-start;
                }
                @media(max-width:900px) {
                    overflow-x: scroll;
                    justify-content: flex-start;
                    gap: 20px;
                }
            }

            .MuiButtonBase-root {
                @include fontSize(16px);
                max-width: 140px;
                position: relative;
                justify-content: flex-start;
                width: 100%;

                &.Mui-selected {
                    color: $black;

                    &:before {
                        background: #6458D8;
                    }
                }

                &:before {
                    content: '';
                    @include position(absolute, $left: 50%, $top: 80px);
                    background: #EFEFF6;
                    @include border-radius(0px 0px 150px 150px);
                    transform: translate(-50%);
                    height: 60px;
                    width: 120px;
                    z-index: -1;
                }
                .tab-label{
                    max-width: 108px;
                    width: 100%;
                }

                img {
                    // margin-bottom: 68px;
                    height: 76px;
                    margin: 0 auto 68px;
                }
            }

            .MuiTabs-indicator {
                display: none;
            }
        }
    }
}