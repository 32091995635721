@import "../../src/CommonSCSS/mixins.scss";

.pagesWrapper {
    padding-top: 76px;
    display: flex;
    flex-direction: column;
    // gap: 80px;

    @media(max-width:767px) {
        padding-top: 60px;
    }

    h1 {
        font-weight: 400;

        span {
            font-weight: 700;
        }

        @media(max-width: 900px) {
            font-size: 40px !important;
            line-height: normal;
        }

        @media(max-width: 600px) {
            font-size: 32px !important;
            line-height: normal;
        }
    }

    h2 {
        font-weight: 700;

        span {
            font-weight: normal;
        }
    }

    p {

        @media(max-width: 700px) {
            font-size: 14px !important;
        }
    }

    .pagesHeader {
        background: linear-gradient(180deg, #FFF 0%, #F8F8F8 100%);
        padding: 40px 12px;

        @media(max-width:700px) {
            padding: 20px 12px;
        }

        h1 {
            font-weight: 400;

            span {
                font-weight: 700;
            }
        }

        h6 {
            font-weight: 600;
            color: $secondary;
        }

        .product_header_background {
            position: relative;
            padding-top: 40px;

            .prod-header-img {
                margin: auto;
            }

            .spec_box {
                padding: 10px 20px;
                border-radius: 10px;
                border: 3px solid rgba(255, 255, 255, 0.50);
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                gap: 12px;

                @media(max-width:1100px) {
                    padding: 10px 8px;
                    gap: 8px;
                }

                p {
                    font-family: 'Quicksand';
                    text-transform: capitalize;
                    @include fontSize(18px);
                    font-weight: 700;
                    text-align: left;

                    @media(max-width:1100px) {
                        @include fontSize(16px);
                    }
                }

                span {
                    svg {
                        height: 24px;
                        width: 24px;
                        background: $black;
                        color: $white;
                        @include border-radius(50%);
                        padding: 4px;
                    }
                }

                &.mas {
                    box-shadow: 0px 0px 16px 0px rgba(13, 157, 169, 0.20);
                    @include position(absolute, $top: 0, $left: 38%);

                    &:hover {
                        box-shadow: 0px 0px 16px 8px rgba(13, 157, 169, 0.20);
                    }

                    p {
                        color: #0D9DA9;
                    }

                    svg {
                        background: #0D9DA9;
                    }
                }

                &.csd {
                    box-shadow: 0px 0px 16px 0px rgba(254, 213, 0, 0.20);
                    @include position(absolute, $top: 10px, $right: 20%);

                    &:hover {
                        box-shadow: 0px 0px 16px 8px rgba(254, 213, 0, 0.20);
                    }

                    @media(max-width:1350px) {
                        right: 0;
                        top: 0;
                    }

                    p {
                        color: #FED500;
                    }

                    svg {
                        background: #FED500;
                    }
                }

                &.ses {
                    box-shadow: 0px 0px 16px 0px rgba(31, 175, 255, 0.20);
                    @include position(absolute, $top: 30%, $left: 7%);

                    &:hover {
                        box-shadow: 0px 0px 16px 8px rgba(31, 175, 255, 0.20);
                    }

                    @media(max-width:1350px) {
                        left: 0;
                        top: 25%;
                    }

                    @media(max-width:1150px) {
                        top: -50px;
                    }

                    p {
                        color: #1FAFFF;
                    }

                    svg {
                        background: #1FAFFF;
                    }
                }

                &.pcs {
                    box-shadow: 0px 0px 16px 0px rgba(254, 154, 154, 0.20);
                    @include position(absolute, $bottom: 10%, $left: 10%);

                    &:hover {
                        box-shadow: 0px 0px 16px 8px rgba(254, 154, 154, 0.20);
                    }

                    @media(max-width:1350px) {
                        bottom: 8%;
                        left: 0;
                    }

                    @media(max-width:1150px) {
                        bottom: -76px;
                    }

                    p {
                        color: #F58484;
                    }

                    svg {
                        background: #F58484;
                    }
                }

                &.fd {
                    box-shadow: 0px 0px 16px 0px rgba(158, 202, 102, 0.20);
                    @include position(absolute, $bottom: 8%, $left: 43%);

                    &:hover {
                        box-shadow: 0px 0px 16px 8px rgba(158, 202, 102, 0.20);
                    }

                    @media(max-width:1350px) {
                        left: 40%;
                    }

                    p {
                        color: #9ECA66;
                    }

                    svg {
                        background: #9ECA66;
                    }
                }

                &.bd {
                    box-shadow: 0px 12px 16px 0px rgba(222, 143, 252, 0.20);
                    @include position(absolute, $bottom: 20%, $right: 8%);

                    &:hover {
                        box-shadow: 0px 12px 16px 8px rgba(222, 143, 252, 0.20);
                    }

                    @media(max-width:1350px) {
                        right: 0;
                        bottom: 15%;
                    }

                    @media(max-width:1150px) {
                        bottom: -76px;
                    }

                    p {
                        color: #DE8FFC;
                    }

                    svg {
                        background: #DE8FFC;
                    }
                }
            }
        }

        .product_header_background_mobile {
            .spec_box {
                padding: 5px;
                gap: 6px;
                border-radius: 10px;
                border: 3px solid rgba(255, 255, 255, 0.50);
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                margin-bottom: 18px;

                &:last-child {
                    margin-bottom: 0;
                }

                p {
                    font-family: 'Quicksand';
                    text-transform: capitalize;
                    @include fontSize(16px);
                    font-weight: 700;
                    text-align: left;
                }

                span {
                    svg {
                        height: 24px;
                        width: 24px;
                        background: $black;
                        color: $white;
                        @include border-radius(50%);
                        padding: 4px;
                    }
                }

                &.mas {
                    box-shadow: 0px 0px 16px 0px rgba(13, 157, 169, 0.20);

                    &:hover {
                        box-shadow: 0px 0px 16px 8px rgba(13, 157, 169, 0.20);
                    }

                    p {
                        color: #0D9DA9;
                    }

                    svg {
                        background: #0D9DA9;
                    }
                }

                &.csd {
                    box-shadow: 0px 0px 16px 0px rgba(254, 213, 0, 0.20);

                    &:hover {
                        box-shadow: 0px 0px 16px 8px rgba(254, 213, 0, 0.20);
                    }

                    p {
                        color: #FED500;
                    }

                    svg {
                        background: #FED500;
                    }
                }

                &.ses {
                    box-shadow: 0px 0px 16px 0px rgba(31, 175, 255, 0.20);

                    &:hover {
                        box-shadow: 0px 0px 16px 8px rgba(31, 175, 255, 0.20);
                    }

                    p {
                        color: #1FAFFF;
                    }

                    svg {
                        background: #1FAFFF;
                    }
                }

                &.pcs {
                    box-shadow: 0px 0px 16px 0px rgba(254, 154, 154, 0.20);

                    &:hover {
                        box-shadow: 0px 0px 16px 8px rgba(254, 154, 154, 0.20);
                    }

                    p {
                        color: #F58484;
                    }

                    svg {
                        background: #F58484;
                    }
                }

                &.fd {
                    box-shadow: 0px 0px 16px 0px rgba(158, 202, 102, 0.20);

                    &:hover {
                        box-shadow: 0px 0px 16px 8px rgba(158, 202, 102, 0.20);
                    }

                    p {
                        color: #9ECA66;
                    }

                    svg {
                        background: #9ECA66;
                    }
                }

                &.bd {
                    box-shadow: 0px 12px 16px 0px rgba(222, 143, 252, 0.20);

                    &:hover {
                        box-shadow: 0px 12px 16px 8px rgba(222, 143, 252, 0.20);
                    }

                    p {
                        color: #DE8FFC;
                    }

                    svg {
                        background: #DE8FFC;
                    }
                }
            }
        }
    }

    .partialSlider {
        .react-multiple-carousel__arrow {
            bottom: 0px;

            &:disabled {
                opacity: 0.3;
            }

            &.react-multiple-carousel__arrow--right,
            &.react-multiple-carousel__arrow--left {
                border: 1px solid rgba($black, 0.3);
                @include border-radius(8px);
                background: $white;
                height: 40px;
                width: 40px;

                &:before {
                    color: $black;
                }
            }

            &.react-multiple-carousel__arrow--left {
                left: calc(50% - 56px);
            }

            &.react-multiple-carousel__arrow--right {
                right: calc(50% - 56px);
            }
        }

        .react-multi-carousel-list {
            padding-bottom: 80px;
            max-width: 1360px;
            margin: 0px auto;
            width: 100%;
        }
    }

    .cogBox {
        padding: 24px;
        background: $white;
        @include border-radius(50px 0px 20px 20px);
        @include box-shadow(0px 16px 20px rgba($black, 0.1));
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 24px;
        // max-width: 600px;
        width: 100%;

        .topCorner {
            @include position(absolute, $left: 0px, $top: 0px);
            border-top: 1px solid;
            border-left: 2px solid;
            @include border-radius(50px 0px 0px 0px);
            height: 80px;
            width: 80px;
            z-index: 1;
        }

        .icon {
            animation: rotate 12s linear infinite;
        }

        @keyframes rotate {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }

        h5 {
            @include fontSize(20px);
            font-weight: 700;
        }

        ul {
            margin-left: 24px;

            li {
                list-style-type: disc;
            }
        }
    }

    .caseStudy {
        padding: 40px 16px 90px 32px;
        border-left: 1px dashed;
        border-bottom: 1px dashed;
        border-top: none;
        border-right: none;
        @include border-radius(1000px 0px 1000px 1000px);
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 300px;
        height: 300px;
        text-align: center;
        align-items: center;
        margin: auto;

        @media(max-width:767px) {
            width: 150px;
            height: 170px;
            padding: 20px 6px 20px 24px;
        }

        .topCorner {
            @include position(absolute, $right: 0px, $top: 0px);
            border-top: 1px solid;
            border-right: 1px solid;
            height: 60px;
            width: 60px;
            z-index: 1;

            @media(max-width:767px) {
                width: 40px;
                height: 40px;
            }
        }

        h5 {
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.50);
            display: inline-block;
            color: #479B2A;
            padding: 10px 16px;
            font-weight: 500;
            @include fontSize(16px);

            @media(max-width:767px) {
                padding: 6px;
                @include fontSize(11px);
            }
        }

        p {
            @media(max-width:767px) {
                @include fontSize(10px);
                line-height: normal;
            }
        }

        .ctaBtn {
            @include position(absolute, $right: 0px, $bottom: 40px);

            @media(max-width:767px) {
                bottom: 15px;
            }

            .whiteSmBtn {
                border-color: $white;
                @include box-shadow(0px 4px 12px rgba($black, 0.15));

                @media(max-width:767px) {
                    @include fontSize(11px);
                    padding: 6px;
                }
            }
        }
    }

    .numberBox {
        display: flex;
        flex-direction: column;
        min-width: 270px;
        flex: 1 0 0;
        margin: 0px 30px;

        .heading {
            @include border-radius(12px);
            background: $white;
            @include box-shadow(0px 12px 20px 0px rgba(0, 0, 0, 0.05));
            padding: 24px 40px;
            margin-bottom: 60px;
            position: relative;

            h4 {
                @include fontSize(20px);
                font-weight: 500;

                &:first-letter {
                    @include fontSize(32px);
                    color: $secondary;
                }
            }

            .numberIcon {
                @include position(absolute, $left: -30px, $bottom: -30px);
                background: lighten($secondary, 30%);
                @include border-radius(40px 40px 0px 40px);
                @include box-shadow(0px 0px 20px 0px rgba(113, 101, 228, 0.20));
                @include fontSize(24px);
                font-weight: 500;
                padding-top: 10px;
                text-align: center;
                color: $secondary;
                height: 60px;
                width: 60px;
            }
        }

        p {
            margin-bottom: 12px;
        }

        ul {
            li {
                color: $secondary;
                position: relative;
                padding-left: 10px;

                &::before {
                    @include position(absolute, $left: 0, $top: 10px);
                    content: '';
                    background: $secondary;
                    height: 4px;
                    width: 4px;
                    border-radius: 50%;
                }
            }
        }
    }

    .dashBorderBox {
        border: 1px dashed $secondary;
        border-top: none;
        @include border-radius(12px);
        padding: 40px 24px 24px;
        position: relative;
        margin-top: 24px;

        h6 {
            @include position(absolute, $left: 24px, $top: -20px);
            background: rgba($secondary, 0.2);
            @include border-radius(8px);
            color: $secondary;
            font-size: 16px;
            font-weight: 600;
            padding: 8px 24px;
        }
    }

    .verticalTabs {
        background: $grey;

        .tabul {
            position: relative;

            &:before {
                background: $secondary;
                content: '';
                @include position(absolute, $left: 22px, $top: 10px);
                height: calc(100% - 80px);
                width: 1px;
                z-index: 0;
            }

            .tabs {
                display: flex;
                align-items: center;
                gap: 4px;
                cursor: pointer;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                margin-bottom: 40px;
                width: 100%;
                position: relative;
                z-index: 1;

                .icon {
                    @include box-shadow(inset 0px 4px 4px rgba($secondary, 0.3));
                    @include border-radius(50px);
                    background: $white;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    height: 50px;
                    width: 50px;

                    div {
                        @include border-radius(50px);
                        background: $secondary;
                        padding: 8px;
                        height: 36px;
                        width: 36px;

                        img {
                            width: 100%;
                        }
                    }
                }

                .title {
                    overflow: hidden;
                    padding: 12px;
                    position: relative;
                    width: 100%;

                    &:before {
                        background: $white;
                        @include position (absolute, $left: 22px, $top: 50%);
                        content: '';
                        width: 100px;
                        height: 100px;
                        transform: translate(0px, -50%) rotate(45deg);
                        z-index: 0;
                    }

                    &:after {
                        background: linear-gradient(90deg, #FFF 50%, rgba(255, 255, 255, 0.00) 100%);
                        @include position (absolute, $left: 26px, $top: 0px);
                        content: '';
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                    }

                    &.active {
                        &:before {
                            background: $secondary;
                        }

                        &:after {
                            background: linear-gradient(90deg, $secondary 50%, rgba(255, 255, 255, 0.00) 100%);
                        }

                        h5 {
                            color: $white;
                        }
                    }

                    h5 {
                        @include fontSize(18px);
                        margin-left: 16px;
                        position: relative;
                        z-index: 9;
                    }
                }
            }
        }

        .contents {
            display: flex;
            flex: 1 0 0;
            flex-direction: column;

            img {
                margin-bottom: 32px;
                max-width: 400px;
            }

            p {
                margin-bottom: 32px;
            }

            ul {
                display: block;

                li {
                    position: relative;
                    @include fontSize(16px);
                    font-weight: 600;
                    margin-bottom: 12px;
                    padding-left: 24px;

                    &:before {
                        content: '';
                        @include position(absolute, $left: 0px, $top: 7px);
                        border-left: 1px solid $black;
                        border-bottom: 1px solid $black;
                        height: 6px;
                        width: 12px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }

    }

    // Product Development
    .development_spectrum {
        .ds_box {
            padding: 24px;
            @include border-radius(12px);

            @media(max-width:600px) {
                padding: 12px;
                box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.10);
            }

            &:hover {
                box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.10);
            }

            img {
                margin: auto
            }

            h5 {
                @include fontSize(18px);
                font-weight: 700;
                margin-bottom: 8px;
                margin-top: 24px;
            }

            .blueMdBtn {
                padding: 12px 24px;
            }
        }
    }

    .our_approach {
        .sd_approach {
            max-width: 984px;
            width: 100%;
            position: relative;
            margin: auto;

            &::before {
                @include position(absolute, $top: 0, $left: calc(50% - 0.5px));
                content: '';
                width: 1px;
                height: 100%;
                background: repeating-linear-gradient(to bottom, transparent, transparent 3px, #828282 3px, #828282 6px);
            }

            .sda_box {
                display: flex;
                position: relative;
                align-items: center;
                margin-bottom: 40px;

                @media(max-width:600px) {
                    margin-bottom: 10px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.even {
                    flex-direction: row-reverse;
                }

                .desc_box {
                    width: calc(50% - 20px);

                    .desc_box_inner {
                        background: linear-gradient(0deg, #F8F8F8 0%, #F8F8F8 100%), #FFF;
                        @include border-radius(12px);
                        padding: 24px;
                        max-width: 412px;

                        @media(max-width:600px) {
                            padding: 12px 8px;

                            p {
                                @include fontSize(13px);
                                text-align: center;
                                line-height: 18px;
                            }
                        }

                        &.text-right {
                            text-align: right;
                        }

                        &.ml-auto {
                            margin-left: auto;
                        }

                        h6 {
                            @include fontSize(20px);
                            font-weight: 700;
                            margin-bottom: 8px;

                            @media(max-width:600px) {
                                @include fontSize(15px);
                                text-align: center;
                            }
                        }

                        p {
                            color: #333;
                        }
                    }
                }

                span {
                    display: inline-block;
                    height: 40px;
                    width: 40px;
                    @include fontSize(24px);
                    font-weight: 500;
                    text-align: center;

                    @media(max-width:600px) {
                        height: 20px;
                        width: 20px;
                        @include fontSize(13px);
                    }
                }

                $box-count: 9; // Number of boxes
                $colors: #F0C85E, #3A6FE5, #FE9A9A, #7BD4F2, #9FA9CF, #ECC5FB, #67989C, #E6B566, #B37D74; // List of colors

                @for $i from 1 through $box-count {
                    $color: nth($colors, $i); // Get a color from the list

                    .box-#{$i} {
                        background-color: $color;
                        display: inline-block;
                        @include border-radius(50%);
                        color: white;
                        margin: 10px;
                    }
                }

                .icon_box {
                    width: calc(50% - 20px);

                    img {
                        margin: auto;

                        @media(max-width:600px) {
                            max-width: 70%;
                        }
                    }
                }
            }
        }
    }

    .discovery_phase {
        h1 {
            @media(max-width:600px) {
                @include fontSize(30px);
            }
        }

        .dp_img {
            @media(max-width:900px) {
                max-width: 70%;
                margin-bottom: 30px !important;
            }
        }

        .discovery_phase_list {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            align-items: center;

            li {
                width: calc(50% - 12px);
                box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.10);
                @include border-radius(12px);
                padding: 24px;

                @media(max-width:600px) {
                    padding: 12px 8px;
                    text-align: center;

                    img {
                        margin: auto;
                    }

                    h5 {
                        margin-top: 0 !important;
                    }

                    hr {
                        margin-top: 10px !important;
                        margin-bottom: 10px !important;
                    }

                    &:nth-child(2) {
                        margin-top: 0px !important;
                    }

                    &:nth-child(3) {
                        margin-top: 0px !important;
                    }
                }

                &:nth-child(2) {
                    margin-top: 100px
                }

                &:nth-child(3) {
                    margin-top: -100px
                }

                h5 {
                    @include fontSize(18px);
                    font-weight: 700;
                    margin-top: 18px;
                }

                hr {
                    border: none;
                    border-top: 1px solid #E0E0E0;
                    margin-top: 18px;
                    margin-bottom: 18px;
                }

                p {
                    line-height: 24px;
                }
            }
        }
    }

    // IT Consulting & Staff Augmentation
    .consulting_services {
        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                width: 50%;
                border-bottom: 2px solid rgba(123, 112, 238, 0.12);

                @media(max-width:767px) {
                    width: 100%;
                    border: none !important;
                }

                &:nth-child(odd) {
                    border-right: 2px solid rgba(123, 112, 238, 0.12);
                }

                &:nth-last-child(1) {
                    border-bottom: none;
                }

                &:nth-last-child(2) {
                    border-bottom: none;
                }

                .box {
                    display: flex;
                    align-items: center;
                    gap: 24px;
                    padding: 24px;

                    @media(max-width:992px) {
                        padding: 12px;
                        gap: 12px;
                    }

                    span {
                        display: inline-block;
                        min-width: 140px;
                        height: 140px;
                        background: #F2F1FF;
                        border-radius: 50%;
                        position: relative;
                        padding: 24px;

                        @media(max-width:992px) {
                            padding: 0;
                            min-width: 100px;
                            height: 100px;
                        }

                        @media(max-width:600px) {
                            padding: 0;
                            min-width: 80px;
                            height: 80px;
                        }

                        img {
                            top: 50%;
                            left: 50%;
                            position: absolute;
                            transform: translate(-50%, -50%);
                        }
                    }

                    h4 {
                        @include fontSize(20px);
                        font-weight: 700;
                        margin-bottom: 12px;

                        @media(max-width:767px) {
                            @include fontSize(16px);
                        }
                    }

                    p {
                        @media(max-width:767px) {
                            @include fontSize(13px);
                        }
                    }
                }
            }
        }
    }

    .staff_augmentation_services {
        img {
            margin: auto;

            @media(max-width:599px) {
                margin-bottom: 24px;
            }
        }

        .sa_service_box {
            padding: 24px;
            border: 1px solid #E0E0E0;
            @include border-radius(12px);

            @media(max-width:599px) {
                padding: 12px;
            }

            @media(max-width:899px) {
                margin-bottom: 24px;
            }

            h4 {
                @include fontSize(20px);
                font-weight: 700;
                margin-bottom: 12px;

                @media(max-width:767px) {
                    @include fontSize(16px);
                }
            }

            p {
                @media(max-width:767px) {
                    @include fontSize(13px);
                }
            }
        }

        .req_option_box {
            background: rgba(123, 112, 238, 0.04);
            @include border-radius(8px);
            padding: 24px;
            position: relative;
            margin-top: 40px;

            @media(max-width:1199px) {
                margin-top: 56px;
            }

            span {
                display: inline-block;
                width: 62px;
                height: 62px;
                @include border-radius(50%);
                background: #F2F1FF;
                @include position(absolute, $top: -40px, $left: 50%);
                transform: translateX(-50%);

                img {
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%)
                }
            }

            h4 {
                @include fontSize(20px);
                font-weight: 700;
                margin-bottom: 12px;

                @media(max-width:767px) {
                    @include fontSize(16px);
                }
            }

            p {
                @media(max-width:767px) {
                    @include fontSize(13px);
                }
            }
        }

        h2 {
            @include fontSize(32px);

            span {
                font-weight: 700;
            }
        }
    }

    .core-goals {
        .core-goals-section {
            background: rgba(123, 112, 238, 0.04);
            padding: 24px;
            @include border-radius(12px);

            @media(max-width:600px) {
                padding: 12px;
            }

            ul {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 24px;

                li {
                    width: calc(25% - 18px);

                    @media(max-width:900px) {
                        width: calc(50% - 12px);
                    }

                    @media(max-width:600px) {
                        width: 100%;
                    }
                }

            }

            .cg_box {
                padding: 24px;
                border-left: 4px solid rgba(113, 101, 228, 0.60);
                border-right: 4px solid rgba(113, 101, 228, 0.60);
                @include border-radius(12px);

                h4 {
                    @include fontSize(20px);
                    font-weight: 700;
                    margin-bottom: 12px;

                    @media(max-width:767px) {
                        @include fontSize(16px);
                    }
                }

                p {
                    @media(max-width:767px) {
                        @include fontSize(13px);
                    }
                }
            }
        }
    }

    // Design Studio
    .digital_studio {
        background: linear-gradient(180deg, #FFF 0%, #F8F8F8 100%);
        padding: 60px 0px;
        min-height: 540px;

        .container_grid {
            width: calc(100% - 220px);

            @media(max-width:700px) {
                width: calc(100% - 150px);
            }
        }

        h2 {
            font-weight: 400;
            @include fontSize(60px);
            font-family: 'Fredericka the Great', serif;
            margin-top: 66px;
            margin-bottom: 200px;
            min-height: 58px;
        }

        p {
            width: calc(100% - 220px);
            @include position(absolute, $bottom: -190px);
            font-weight: 400;
            @include fontSize(20px);
            font-family: 'Quicksand';
            line-height: 28px;

            span {
                font-weight: 600;
                display: block;
            }
        }

        @media(max-width:700px) {
            padding: 20px 0px;
            min-height: 280px;

            .typing-heading {
                line-height: 50px;
                font-size: 36px !important;
                margin-top: 15px;
            }

            p {
                @include fontSize(14px);
                width: calc(100% - 190px);
                @include position(absolute, $bottom: -120px);
            }
        }

        @media(max-width:660px) {
            p {
                @include position(absolute, $bottom: -80px);

                span {
                    display: unset;
                }
            }
        }

        @media(max-width:420px) {
            .typing-heading {
                font-size: 24px !important;
                line-height: 1.7;
            }

            p {
                @include fontSize(12px)
            }
        }

        .animateObject {
            @include position(absolute, $left: 12px, $top: 12px);
            height: 400px;
            overflow: hidden;
            width: 98%;

            .circle {
                width: 204px;
                position: relative;
                animation: slideCircle 3s forwards;
            }

            .pencil {
                position: relative;
                animation: slidePencil 2.5s forwards;
                animation-delay: 0.5s;
                left: -120px;
                top: 32px;
                width: 0px;
                height: 130px;

                &:before {
                    content: '';
                    background: $black;
                    @include position(absolute, $left: 0px, $bottom: 0.5px);
                    height: 3px;
                    width: calc(100% - 36px);
                }

                img {
                    height: 130px;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                }
            }

            .scale {
                width: 116px;
                position: relative;
                animation: slideScale 2s forwards;
                animation-delay: 1s;
                top: 16px;
                left: -117px;
            }

            .infinity {
                width: 116px;
                position: absolute;
                animation: rotation 3s linear infinite, fadeIn 2s ease-in-out;
                top: 70px;
                right: 220px;
            }

            @keyframes slideCircle {
                from {
                    left: 0px;
                    opacity: 0;
                }

                to {
                    left: calc(100% - 380px);
                    opacity: 1;
                }
            }

            @keyframes slidePencil {
                from {
                    width: 0px;
                    opacity: 0;
                }

                to {
                    width: calc(100% - 121px);
                    opacity: 1;
                }
            }

            @keyframes slideScale {
                from {
                    left: 0px;
                    opacity: 0;
                }

                to {
                    left: calc(100% - 336px);
                    opacity: 1;
                }
            }

            @keyframes rotation {
                0% {
                    transform: rotateX(0deg);
                }

                100% {
                    transform: rotateX(360deg);
                }
            }

            @keyframes fadeIn {
                from {
                    opacity: 0;
                }

                to {
                    opacity: 1;
                }
            }

            @media(max-width:1200px) {
                .pencil {
                    left: 20px;

                    &:before {

                        @include position(absolute, $left: -20px, $bottom: 0.5px);
                        height: 3px;
                        width: calc(100% - 18px);
                    }
                }

                .infinity {
                    right: 70px;
                }

                @keyframes slideCircle {
                    from {
                        left: 0px;
                        opacity: 0;
                    }

                    to {
                        left: calc(100% - 230px);
                        opacity: 1;
                    }
                }

                @keyframes slideScale {
                    from {
                        left: 0px;
                        opacity: 0;
                    }

                    to {
                        left: calc(100% - 186px);
                        opacity: 1;
                    }
                }

                @keyframes slidePencil {
                    from {
                        width: 0px;
                        opacity: 0;
                    }

                    to {
                        width: calc(100% - 111px);
                        opacity: 1;
                    }
                }
            }

            @media(max-width:700px) {
                .circle {
                    width: 114px;
                }

                .infinity {
                    width: 70px;
                    @include position(absolute, $top: 40px, $right: 57px);
                    animation: rotation 3s linear infinite, fadeIn 2s ease-in-out;
                }

                .scale {
                    width: 64px;
                    @include position(absolute, $top: 124px);
                }

                .pencil {
                    left: 0px;

                    &:before {
                        @include position(absolute, $left: -20px, $bottom: 50px);
                        height: 3px;
                        width: calc(100% - -40px);
                    }

                    img {
                        height: 80px;
                        right: -40px;
                    }
                }

                @keyframes slideCircle {
                    from {
                        left: 0px;
                        opacity: 0;
                    }

                    to {
                        left: calc(100% - 148px);
                        opacity: 1;
                    }
                }

                @keyframes slideScale {
                    from {
                        left: 0px;
                        opacity: 0;
                    }

                    to {
                        left: calc(100% - 123px);
                        opacity: 1;
                    }
                }
            }
        }

    }

    .Which_dir {
        padding: 80px 0px;

        @media(max-width:700px) {
            padding: 40px 0;
        }

        .head {
            h2 {
                font-weight: 400;
            }

            span {
                font-weight: 700;
            }
        }

        .concept {
            display: flex;
            flex-direction: column;
            justify-content: end;
            padding: 24px;

            @media(max-width:700px) {
                padding: 0 12px;
            }

            .img_sec {
                display: flex;
                justify-content: center;
            }

            .content_sec {
                h4 {
                    font-weight: 700;
                }
            }
        }

        .concept_visual {
            .img_sec {
                display: flex;
                justify-content: center;
            }
        }

    }

    .detailed_approach {
        .head {
            h2 {
                font-weight: 400;
            }

            span {
                font-weight: 700;
            }
        }

        .approach_ul {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            row-gap: 80px;
            column-gap: 24px;

            .approach_box {
                display: flex;
                align-items: center;
                height: 100%;
                gap: 10px;
            }

            @media(max-width:1200px) {
                flex-direction: column;
                align-items: center;
                row-gap: 30px;

                .approach_box {
                    justify-content: center;

                    img {
                        max-width: 100px;
                    }

                    .content_section {
                        max-width: calc(100% - 480px);

                        .content {
                            max-width: unset;
                        }

                        @media(max-width:992px) {
                            max-width: 100%;
                        }
                    }
                }
            }

            li {
                width: calc(50% - 12px);
                position: relative;
                height: 333px;

                &:before {
                    content: "";
                    @include position(absolute, $top: 0, $left: 50%);
                    z-index: -1;
                    height: 333px;
                    max-width: 513px;
                    width: 100%;
                    transform: translateX(-50%);
                    background: url('../../public/images/li-bg1.svg') no-repeat center/100%;
                }

                &:after {
                    content: "";
                    background: url('../../public/images/arrow.svg') no-repeat center/100%;
                    height: 128px;
                    width: 133px;
                    @include position(absolute, $top: 36%, $right: -5%)
                }

                @media(max-width:1350px) {
                    &:after {
                        display: none;
                    }
                }

                @media(max-width:1200px) {
                    width: calc(80% - 12px);

                }

                &:nth-child(2) {
                    &:before {
                        background: url('../../public/images/li-bg2.svg') no-repeat center/100%;
                    }

                    &:after {
                        background: url('../../public/images/arrow2.svg') no-repeat center/100%;
                        @include position(absolute, $top: 94%, $right: 23%)
                    }
                }

                &:nth-child(3) {
                    &:before {
                        background: url('../../public/images/li-bg3.svg') no-repeat center/100%;
                        transform: translateX(-35%);
                    }

                    &:after {
                        background: url('');
                    }

                    img {
                        margin-left: 78px;
                    }

                    .content {
                        max-width: calc(100% - 30px);
                    }

                    @media(max-width:1350px) {
                        &:before {
                            transform: translateX(-50%);
                        }

                        img {
                            margin: 0;
                        }

                        .content {
                            max-width: calc(100% - 110px);
                        }
                    }

                    @media(max-width:900px) {
                        .content {
                            max-width: calc(100% - 110px);
                        }
                    }
                }

                &:nth-child(4) {
                    &:before {
                        background: url('../../public/images/li-bg4.svg') no-repeat center/100%;
                        transform: translateX(-35%);
                    }

                    &:after {
                        background: url('../../public/images/arrow3.svg') no-repeat center/100%;
                        @include position(absolute, $top: 33%, $left: -6%)
                    }

                    img {
                        margin-left: 78px;
                    }

                    .content {
                        max-width: calc(100% - 30px);
                    }

                    @media(max-width:1350px) {
                        &:before {
                            transform: translateX(-50%);
                        }

                        img {
                            margin: 0;
                        }

                        .content {
                            max-width: calc(100% - 110px);
                        }
                    }

                    @media(max-width:900px) {
                        .content {
                            max-width: calc(100% - 110px);
                        }
                    }
                }

                @media(max-width:767px) {
                    width: 100%;
                    text-align: center;

                    .content_section {
                        max-width: calc(100% - 340px);

                        .content {
                            max-width: unset;
                        }
                    }
                }

                @media(max-width:650px) {
                    .content_section {
                        max-width: calc(100% - 250px);

                        .content {
                            max-width: unset;
                        }
                    }
                }
            }

            h4 {
                font-weight: 700;
                margin-bottom: 12px;
            }

            .content {
                padding-left: 20px;
                border-left: 1px solid #000;
                max-width: calc(100% - 110px);
                margin-left: 10px;

                h6 {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }

    .believe {
        padding: 80px 0px;

        @media(max-width:700px) {
            padding: 40px 0;
        }

        .head {
            margin-bottom: 40px;

            h2 {
                font-weight: 400;
            }

            span {
                font-weight: 700;
            }

            @media(max-width:700px) {
                margin-bottom: 20px;
            }
        }

        .content {
            .content_container {
                row-gap: 80px;

                @media(max-width:700px) {
                    row-gap: 40px;

                    h3 {
                        @include fontSize(20px)
                    }
                }

                h3 {
                    font-weight: 700;
                }

                .points {
                    display: flex;
                    gap: 24px;

                    span {
                        min-width: 50px;
                        height: 50px;
                        background: #F2F2F2;
                        border-radius: 25px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 24px;
                        font-weight: 400;
                    }

                    div h5 {
                        font-weight: 700;
                        margin-bottom: 14px;
                    }

                    @media(max-width:700px) {
                        div h5 {
                            @include fontSize(18px)
                        }
                    }
                }
            }
        }
    }

    .we_create {
        .head {
            margin-bottom: 80px;

            @media(max-width:700px) {
                margin-bottom: 40px;
            }

            h2 {
                font-weight: 400;
            }

            span {
                font-weight: 700;
            }
        }



        .bottom_sec {
            position: relative;
            width: 100%;

            @media(max-width:1100px) {
                display: flex;
                align-items: center;
                height: 800px;
            }

            @media(max-width:700px) {
                height: 1100px;
            }

            img {
                margin: auto;
            }

            .spec_box {
                display: inline-block;
                padding: 24px;
                max-width: 332px;
                text-align: center;
                @include border-radius(10px);
                border: 3px solid rgba(255, 255, 255, 0.50);
                @include position(absolute, $bottom: 26px, $right: 119px);
                @include box-shadow(0px 0px 16px 0px rgba(222, 143, 252, 0.20));

                @media(max-width:1350px) {
                    @include position(absolute, $right: 0px);
                    padding: 12px;
                }

                @media(max-width:700px) {
                    @include position(absolute, $bottom: 0px);
                }

                .spec_heading {
                    @include fontSize(18px);
                    font-weight: 700;
                    color: #DE8FFC;
                }

                .spec_msg {
                    @include fontSize(14px);
                    font-weight: 400;
                    font-family: Quicksand;
                    line-height: normal;
                }

                &:nth-child(2) {
                    @include position(absolute, $top: 26px, $left: 0px, $bottom: unset);
                    @include box-shadow(0px 0px 16px 0px rgba(13, 157, 169, 0.20));

                    @media(max-width:700px) {
                        @include position(absolute, $top: 0px);
                    }

                    .spec_heading {
                        color: #0D9DA9;
                    }

                }

                &:nth-child(3) {
                    @include position(absolute, $top: 26px, $right: 0px, $bottom: unset);
                    @include box-shadow(0px 0px 16px 0px rgba(31, 175, 255, 0.20));

                    @media(max-width:700px) {
                        @include position(absolute, $top: 15%);
                    }

                    .spec_heading {
                        color: #1FAFFF;
                    }


                }

                &:nth-child(4) {
                    @include position(absolute, $bottom: 26px, $left: 119px);
                    @include box-shadow(0px 0px 16px 0px rgba(148, 204, 121, 0.20));

                    @media(max-width:1350px) {
                        @include position(absolute, $left: 0px);
                    }

                    @media(max-width:700px) {
                        @include position(absolute, $bottom: 16%);
                    }

                    .spec_heading {
                        color: #94CC79;
                    }
                }

            }

        }
    }

    .case_study {
        padding: 80px 0px;

        @media(max-width:700px) {
            padding: 40px 0;
        }

        .head {
            h2 {
                font-weight: 400;
            }

            span {
                font-weight: 700;
            }
        }
    }

    .design_quotation {
        padding-bottom: 80px;

        @media(max-width:700px) {
            padding-bottom: 40px;
        }
    }

    .requestQout {
        .tabBox {
            .MuiTabs-indicator {
                display: none;
            }

            .MuiTabs-flexContainer {
                outline: 1px solid #6458D8;
                outline-offset: -1px;
                max-width: 252px;
                margin: auto;
                margin-bottom: 20px;
                border-radius: 30px;

                button {
                    color: #000000;
                    font-weight: 500;

                    &.active {
                        color: #fff;
                        background-color: $secondary;
                        border-radius: 30px;
                    }
                }
            }
        }

        .tabPanel {
            padding: 0;

            .request {
                display: flex;
                justify-content: center;
            }
        }

        .calendlyTab {
            margin-top: -67px;

            @media (max-width:673px) {
                margin-top: 0;
            }
        }
    }

    // Growth & Marketing
    .growth_header {
        padding: 40px 0px;
        background: #F8F8F8;

        .head {
            h1 {
                font-weight: 600;
            }

            span {
                font-weight: 400;
            }

            img {
                margin: auto;
            }

            .edu_header {
                margin-top: 74px;
            }
        }

        @media(max-width:700px) {
            padding-top: 20px;
        }
    }

    .our_services {
        padding: 80px 0px;

        .head {
            h2 {
                font-weight: 700;
            }
        }

        @media(max-width:700px) {
            padding: 40px 0px;

            p {
                margin-bottom: 0;
            }
        }

        @media(max-width:1200px) {
            .sectionImg {
                display: flex;
                justify-content: center;
            }
        }

        .growth_carousel {
            padding-bottom: 80px;

            .main_box {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 0 24px;
                border-right: 1px solid #E0E0E0;

                @media(max-width:700px) {
                    padding: 0;
                    border: none;
                }

                .img_box {
                    display: flex;
                    align-items: center;

                    img {
                        border-radius: 12px;
                        background: linear-gradient(180deg, #FFF 0%, #F8F8F8 100%);
                        padding: 10px;
                        margin-right: 12px;
                        width: 78px;
                        height: 78px;
                    }

                    h6 {
                        @include fontSize(20px);
                        font-weight: 700;
                    }
                }

                p {
                    color: #333
                }

                a {
                    color: $secondary;
                    font-weight: 500;
                }
            }

            .react-multiple-carousel__arrow {
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 8px;
                background: #ffffff;
                height: 40px;
                width: 40px;
                bottom: 0;
                z-index: 0;
            }

            .react-multiple-carousel__arrow--left {
                left: calc(50% - 56px);

                &:before {
                    color: #000000;
                }
            }

            .react-multiple-carousel__arrow--right {
                right: calc(50% - 56px);

                &:before {
                    color: #000000;
                }
            }
        }
    }

    .growth_ride {
        .head {
            h2 {
                font-weight: 400;

                span {
                    font-weight: 700;
                }
            }
        }

        .content {
            width: 100%;

            .boxes_row {
                display: flex;
                justify-content: space-between;

                @media(max-width:1000px) {
                    justify-content: space-around;
                    flex-wrap: wrap;
                    row-gap: 12px;
                }

                .main_box {
                    position: relative;
                    border-radius: 12px;
                    background: linear-gradient(180deg, #FFF 0%, #F8F8F8 100%);
                    padding: 24px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 12px;
                    text-align: center;
                    width: 241px;

                    &:after {
                        border: 1px solid #7B70EE;
                        transform: translateX(-50%);
                        @include position (absolute, $right: -166px, $top: 50%);
                        content: '';
                        width: 40%;
                        height: 1px;
                        z-index: 0;

                        @media(max-width:1400px) {
                            display: none;
                        }
                    }

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }

                    h5 {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .growth_usp {
        background: #F8F8F8;
        padding: 40px 0;
        margin: 80px 0;

        @media(max-width:700px) {
            margin: 40px 0;
        }

        .head {
            h2 {
                font-weight: 400;

                span {
                    font-weight: 700;
                }
            }
        }

        .content {
            .content_box {
                display: flex;
                flex-direction: column;
                gap: 24px;
                align-items: center;
                justify-content: center;

                .usp_content {
                    display: flex;
                    align-items: baseline;
                    border-radius: 12px;
                    border-bottom: 2px dashed #7B70EE;
                    background: linear-gradient(180deg, #FFF 0%, #F8F8F8 100%);
                    box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.10);
                    padding: 24px;

                    &:nth-child(2) {
                        border-bottom: 2px dashed #E38DDD;
                    }

                    &:nth-child(3) {
                        border-bottom: 2px dashed #F36F56;
                    }

                    img {
                        margin-right: 10px;
                    }
                }

                .usp_content2 {
                    border-bottom: 2px dashed #FFC444;

                    &:nth-child(2) {
                        border-bottom: 2px dashed #94CC79;
                    }

                    &:nth-child(3) {
                        border-bottom: 2px dashed #80E4DC;
                    }
                }
            }
        }
    }

    // Education & E-Learning
    .edu_header {
        padding: 80px 0;
        background: #F8F8F8;

        @media(max-width:900px) {
            padding: 40px 0;
        }

        .head {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 24px;

            h1 {
                display: flex;
                flex-direction: column;
                font-weight: 600;

                span {
                    font-weight: 400;
                }
            }
        }
    }

    .growth_quotation {
        padding: 80px 0;

        @media(max-width:700px) {
            padding: 40px 0;
        }
    }

    .edu_service {
        padding: 80px 0px;

        @media(max-width:700px) {
            padding: 40px 0;

            p {
                margin-bottom: 0;
            }
        }

        .head {
            h2 {
                span {
                    font-weight: 400;
                }
            }
        }

        .content_box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 24px;
            text-align: center;
            gap: 16px;

            h5 {
                font-weight: 700;
            }

            p {
                color: #000;
            }

            @media(max-width:700px) {
                padding: 0;

                h5 {
                    @include fontSize(18px);
                }
            }
        }
    }

    .edu_portfolio {
        background: linear-gradient(180deg, #FFF 0%, #F8F8F8 100%);
        padding-bottom: 80px;

        @media(max-width:700px) {
            padding-bottom: 40px;
        }

        .head {
            h2 {
                span {
                    font-weight: 400;
                }
            }

            @media(max-width:700px) {
                margin-bottom: 20px;
            }
        }

        .edu_aproach {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 24px;
            height: 100%;

            h4 {
                color: $secondary;
            }

            p {
                color: #393939;
            }
        }

        .points {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 24px;
            max-height: 437px;
            overflow-y: scroll;
            padding-right: 24px;

            @media(max-width:1200px) {
                max-height: 330px;
                padding-right: 12px;
                gap: 12px;
            }

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $secondary;
                border-radius: 20px;
            }

            &::-webkit-scrollbar-track {
                background-color: #F2F2F2;
                border-radius: 20px;
            }

            .point_section {
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 12px;
                padding: 8px 8px 8px 12px;

                &:nth-child(2),
                &:nth-child(6) {
                    .number .line1 {
                        border: 1px solid #F2C94C;
                    }
                }

                &:nth-child(3),
                &:nth-child(7) {
                    .number .line1 {
                        border: 1px solid #EB5757;
                    }
                }

                &:nth-child(4),
                &:nth-child(8) {
                    .number .line1 {
                        border: 1px solid #2D9CDB;
                    }
                }

                .number {
                    padding: 8px;

                    .count {
                        font-weight: 600;
                        color: #000;
                    }

                    .line1 {
                        border: 1px solid #BEADFA;
                        width: 28px;
                        height: 2px;
                        margin: 10px 0 0 -4px;
                    }
                }

                .heading {
                    font-weight: 500;
                }
            }

            .active {
                border-radius: 4px;
                background: $secondary;

                .number .count,
                p {
                    color: $white;
                }
            }
        }
    }

    .edu_quotation {
        padding-bottom: 80px;

        @media(max-width:700px) {
            padding-bottom: 40px;
        }
    }

    // Retail & E-Commerce
    .retail_header {
        padding: 37px 0;

        .head {
            margin-top: 40px;

            @media (max-width:700px) {
                margin-top: 0;
            }

            h5 {
                color: $secondary;
                font-weight: 700;
            }
        }

        .bottom_content {
            display: flex;
            justify-content: center;
            margin-bottom: 40px;

            @media (max-width:700px) {
                margin-bottom: 0;
            }
        }
    }

    .retail_products {
        padding-top: 80px;

        @media(max-width:700px) {
            padding-top: 40px;
        }

        .head {
            h2 {
                font-weight: 600;

                span {
                    font-weight: 400;
                }
            }
        }

        .retail_carousel {
            padding-bottom: 67px;

            ul {
                li {
                    .main_box {
                        margin: 0 12px;
                        padding: 24px;
                        border-radius: 12px;
                        border: 1px solid #F2F2F2;
                        background: linear-gradient(180deg, #FFF 0%, #F8F8F8 100%);

                        .img_box {
                            display: flex;
                            justify-content: end;
                            margin-bottom: 24px;
                        }

                        h5 {
                            font-weight: 700;
                            margin-bottom: 12px;
                        }
                    }

                    &:nth-child(even) {
                        .main_box {
                            margin-top: 108px;

                            @media(max-width:700px) {
                                margin-top: unset;
                            }
                        }
                    }
                }
            }

            .react-multiple-carousel__arrow {
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 8px;
                background: #ffffff;
                height: 40px;
                width: 40px;
                bottom: 0;
                z-index: 0;
            }

            .react-multiple-carousel__arrow--left {
                left: calc(50% - 56px);

                &:before {
                    color: #000000;
                }
            }

            .react-multiple-carousel__arrow--right {
                right: calc(50% - 56px);

                &:before {
                    color: #000000;
                }
            }
        }
    }

    .retail_services {
        padding: 80px 0;

        @media(max-width:700px) {
            padding: 40px 0;
        }

        .head {
            h2 {
                font-weight: 600;

                span {
                    font-weight: 400;
                }
            }
        }

        .service_image {
            display: flex;
            justify-content: center;
            align-items: center;

            @media(max-width:700px) {
                img {
                    margin-bottom: 40px;
                }
            }
        }

        .services {
            gap: 70px;
            display: flex;
            flex-direction: column;

            .service_box {
                display: flex;
                gap: 24px;
                padding: 24px;
                position: relative;

                @media(max-width:700px) {
                    padding: 0;

                    .right_section {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }

                &:after {
                    content: "";
                    @include position(absolute, $top: calc(100% - 24px), $left: 60%);
                    z-index: 1;
                    width: 108px;
                    height: 90px;
                    transform: translateX(-50%);
                    background: url('../../public/images/service_arrow.svg') no-repeat center/100%;

                    @media(max-width:700px) {
                        @include position(absolute, $top: calc(100% - 12px), $left: 60%);
                        width: 60px;
                    }
                }

                img {
                    max-width: unset;
                }

                h5 {
                    font-weight: 700;
                }

                p {
                    color: #393939;
                }

                &:nth-child(2) {
                    &:after {
                        transform: translateX(-50%) scaleX(-1);
                    }
                }

                &:last-child {
                    &:after {
                        display: none;
                    }

                }
            }
        }
    }

    .retail_path {
        .head {
            h2 {
                font-weight: 600;

                span {
                    font-weight: 400;
                }
            }

            @media(max-width:767px) {
                width: 100%;
                padding: 0;
            }
        }

        .content {
            position: relative;

            @media(max-width:767px) {
                width: 100%;
                padding: 0;
            }

            .border_div {
                @include position(absolute, $top: 26px, $left: 10px);
                background: linear-gradient(135deg, rgba(113, 101, 229, 0.8941176471), rgba(113, 101, 228, 0) 46%);
                height: 75%;
                width: 35%;
                z-index: -1;
                border-radius: 12px;

                @media(max-width:767px) {
                    @include position(absolute, $top: -2px, $left: -2px);
                }
            }

            span {
                @include position(absolute, $top: 43px, $left: 36px);
                color: rgba(123, 112, 238, 0.36);
                @include fontSize(40px);
                font-weight: 700;
                line-height: normal;

                @media(max-width:767px) {
                    @include position(absolute, $top: 0px, $left: 12px);
                }
            }

            .content_box {
                border-radius: 12px;
                border: none;
                background: $white;
                box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.10);
                padding: 24px;
                text-align: center;

                @media(max-width:767px) {
                    padding: 12px;
                }

                img,
                h5 {
                    margin: 0 auto;
                    font-weight: 600;
                    margin-bottom: 12px;
                }
            }
        }
    }

    // Transportation & Logistics
    .trans_path {
        padding: 80px 0;

        @media(max-width:700px) {
            padding: 40px 0;
        }

        img {
            padding: 0 12px;
        }

        .head {
            h2 {
                font-weight: 600;

                span {
                    font-weight: 400;
                }
            }
        }

        .section1 {
            .main_box {
                .trans_box {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    border-radius: 12px;
                    background: #EFFFE8;
                    padding: 10px;

                    h5 {
                        font-weight: 600;
                        margin-bottom: 12px;
                    }
                }

                &:nth-child(2) {
                    .trans_box {
                        background: #F7F6FF;
                    }
                }
            }
        }

        .section2 {
            .main_box {
                .trans_box {
                    background: #EDF3FF;
                }

                &:nth-child(2) {
                    .trans_box {
                        background: #FCEDFF;
                    }
                }

                &:nth-child(3) {
                    .trans_box {
                        background: #FFF9EC;
                    }
                }

                &:nth-child(4) {
                    .trans_box {
                        background: #FFF5F3;
                    }
                }
            }
        }
    }

    .trans_offers {
        padding: 80px 0;
        background: linear-gradient(180deg, #FFF 0%, #F8F8F8 100%);

        @media(max-width:700px) {
            padding: 0 0 40px;
        }

        .head {
            h2 {
                font-weight: 600;

                span {
                    font-weight: 400;
                }
            }

            @media(max-width:767px) {
                width: 100%;
                padding: 0;
            }
        }

        .content {
            width: 100%;
            max-height: 270px;
            overflow-y: scroll;
            padding-right: 34px;

            @media(max-width:1200px) {
                max-height: 170px;
                padding-right: 12px;
            }

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $secondary;
                border-radius: 20px;
            }

            &::-webkit-scrollbar-track {
                background-color: #F2F2F2;
                border-radius: 20px;
            }

            .main_box {
                display: flex;
                align-items: flex-start;

                img {
                    margin-right: 14px;
                }

                h6 {
                    @include fontSize(16px);
                    font-weight: 500;

                    span {
                        font-weight: 300;
                        color: #000;
                    }
                }
            }
        }
    }

    // Travel & Hospitality
    .travel_header {
        h1 span {
            font-weight: 600;
        }
    }

    .travel_service {
        h2 {
            margin-bottom: 0 !important;

            span {
                font-weight: 400;
            }
        }

        .main_box {
            border-radius: 12px;
            background: #FFF;
            box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.10);
            padding: 24px 12px 24px 24px;
            margin-bottom: 24px;
            position: relative;

            &:before {
                @include position(absolute, $top: 50%, $left: 0);
                content: "";
                width: 4px;
                height: 60px;
                border-radius: 0 10px 10px 0;
                background-color: $secondary;
                transform: translateY(-50%);
            }
        }
    }

    .travel_keys {
        padding: 80px 0 0;

        @media(max-width: 700px) {
            padding: 40px 0;
        }

        .content {
            padding: 24px 12px;

            @media(max-width:600px) {
                padding: 24px 0;
            }

            span {
                color: #828282;
                font-weight: 700;
                margin-bottom: 12px;
            }

            h5 {
                font-weight: 700;
                margin-top: 12px;
            }

            .seprator {
                border-bottom: 1px solid#E0E0E0;
                margin-bottom: 14px;
            }

            p {
                color: #000;
                margin-bottom: 14px;
            }
        }
    }

    .travel_offers {
        .content {
            max-height: 305px;
        }
    }

    // Automotive
    .auto_expertise {
        padding: 80px 0;

        @media(max-width:700px) {
            padding: 40px 0;
        }

        .main_box {
            display: flex;
            border-radius: 12px;
            border-top: 1px solid #FED500;
            border-bottom: 1px solid #FED500;
            padding: 24px;

            p {
                font-weight: 600;
            }
        }
    }

    .auto_solutions {
        .content {
            padding-right: 20px;
            max-height: 390px;

            @media(max-width:700px) {
                max-height: 310px;
            }

            .main {
                background: transparent;
                border-radius: unset;
                color: unset;
                box-shadow: unset;
                position: unset;
                margin: 0;
                padding: 0;
                margin-bottom: 12px;

                .headBox {
                    padding: 0;
                    min-height: 24px;

                    .MuiAccordionSummary-content {
                        margin: 0;

                        p {
                            display: flex;
                            align-items: center;
                            font-weight: 500;

                            img {
                                margin-right: 12px;
                            }
                        }
                    }

                    .MuiAccordionSummary-expandIconWrapper {
                        color: #000;
                    }
                }

                .bodyBox {
                    padding: 0;

                    p {
                        display: flex;
                        align-items: baseline;
                        padding-left: 30px;
                        margin-bottom: 10px;
                        font-weight: 300;

                        img {
                            padding-right: 8px;
                        }
                    }
                }
            }
        }
    }

    // Media & Entertainment
    .mediaEntertaiment-expertise {
        $icons_and_colors: (
            light-purple,
            #E3DEFC),
            (light-green, #DFFDEC),
            (light-orange, #FCEEDF),
            (light-pink, #FFE4E4),
            (light-yellow, #DEEDC7
        );

        @for $i from 1 through length($icons_and_colors) {
            $pair: nth($icons_and_colors, $i);
            $icon: nth($pair, 1);
            $color: nth($pair, 2);

            .#{$icon} {
                @include border-radius(12px);
                background: $color;
                padding: 24px;
                margin-bottom: 24px;
                min-height: 281px;
            }

            .read_more {
                font-weight: 500;
                display: inline-block
            }
        }
    }

    .crafting_custom_section {
        .crafting_custtom_box {
            @include border-radius(24px);
            padding: 204px 24px 30px;
            background: linear-gradient(180deg, #FFF 0%, #F8F8F8 100%);
            position: relative;
            margin-top: 120px;
            text-align: center;

            @media(max-width:1199px) {
                padding-top: 154px;
            }

            @media(max-width:700px) {
                margin-top: 90px;
                padding: 110px 12px 12px;
            }

            &:hover {
                background: #EAE8FF;
            }

            .iconbox {
                border-radius: 24px;
                border: 1px solid #EAE8FF;
                background: linear-gradient(180deg, #FFF 0%, #F8F8F8 100%);
                height: 270px;
                width: 380px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include position(absolute, $left: calc(50% - 190px), $top: -90px);

                @media(max-width:1199px) {
                    height: 200px;
                    width: 300px;
                    @include position(absolute, $left: calc(50% - 150px), $top: -90px);
                }

                @media(max-width:700px) {
                    height: 160px;
                    width: 250px;
                    @include position(absolute, $left: calc(50% - 125px), $top: -70px);
                }

                img {
                    @media(max-width:1199px) {
                        max-width: 60%;
                    }

                    @media(max-width:700px) {
                        max-width: 50%;
                    }
                }
            }

            .read_more {
                font-weight: 500;
                display: inline-block;
            }
        }
    }

    // erp pagecss
    .erpHeader {
        @extend .pagesHeader;
        background: none;
        padding-bottom: 0;

        .erp_header_background {
            img {
                margin: auto;
                max-width: 900px;
                width: 100%;
            }
        }
    }

    .erp_offerings {
        background: #F8F8F8;
        padding: 80px 0px;
        @media(max-width:600px){
            padding: 30px 0px;
        }

        .offerings_tab_sec {
            .MuiTabs-root {
                .MuiTabs-flexContainer {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 30px;
                    @media(max-width:600px){
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 12px;
                    }

                    button {
                        @include border-radius(20px);
                        background: #f2f2f2;
                        border-left: 4px solid #BDBDBD;
                        padding: 16px 30px;
                        font-family: 'Quicksand';
                        @include fontSize(24px);
                        font-weight: 700;
                        max-width: 100%;
                        width: 100%;
                        align-items: flex-start;
                        font-weight: 500;

                        @media(max-width:600px) {
                            padding: 12px 16px;
                            @include fontSize(14px);
                            width: calc(50% - 6px);
                            justify-content: center;
                            align-items: baseline;
                        }

                        &.Mui-selected {
                            background: $secondary;
                            color: $white;
                            border-left: 4px solid #FFC444;
                        }
                    }
                }

                .MuiTabs-indicator {
                    display: none;
                }
            }
            .MuiTabPanel-root{
                padding: 0;
                img{
                    margin: auto;
                    max-height: 340px;
                }
                .points_box{
                    margin-top: 24px;
                    max-height: 360px;
                    overflow-y: auto;
                    ul{
                        li{
                            display: flex;
                            gap: 24px;
                            margin-bottom: 20px;
                            padding-right: 10px;
                            span{
                                display: inline-block;
                                border-radius: 50%;
                                min-width: 50px;
                                height: 50px;
                                background: #f2f2f2;
                                font-family: 'roboto';
                                @include fontSize(24px);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            .point_desc{
                                h4{
                                    @include fontSize(20px);
                                    margin-bottom: 12px;
                                }
                            }
                        }
                    }
                }
                .ctaBtn{
                    .blueMdBtn{
                        margin-left: 74px;
                    }
                }
            }
        }
    }

    .erp_expertise_sec{
        .content {
            padding: 12px 24px;
            span{
                color: #828282;
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 12px;
            }
    
            h5 {
                font-weight: 700;
                @include fontSize(20px);
            }

            .separator{
                height: 1px;
                background: #E0E0E0;
                margin-bottom: 14px;
                margin-top: 24px;
            }
    
            p {
                color: #000;
            }
    
            @media(max-width:700px) {
                padding: 0;
    
                h5 {
                    @include fontSize(18px);
                }
            }
        }
    }
}