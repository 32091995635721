@import '../../../CommonSCSS/mixins.scss';

.navbarMobile {
    background: $white;
    border-bottom: 1px solid rgba($black, 0.1);
    @include position(fixed, $top:0px, $left:0px);
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    z-index: 991;
    .logo {
        height: 30px;
    }
    .menuBars {
        border: 1px solid rgba($black, 0.1);
        @include border-radius(4px);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 40px;
        span {
            background: $secondary;
            @include border-radius(4px);
            display: block;
            position: relative;
            height: 2px;
            width: 22px;
            &:before {
                content: '';
                @include position(absolute, $left:50%, $top:-6px);
                background: $secondary;
                @include border-radius(4px);
                transform: translate(-50%);
                height: 2px;
                width: 22px;
            }
            &:after {
                content: '';
                @include position(absolute, $left:50%, $top:6px);
                background: $secondary;
                @include border-radius(4px);
                transform: translate(-50%);
                height: 2px;
                width: 22px;
            }
        }
    }
    .menuWrapper {
        @include position(fixed, $right: 0px, $top: 0px);
        background: rgba($black, 0.2);
        height: 100%;
        width: 100%;
        z-index: 9;
        .innerWrapper {
            background: $white;
            @include box-shadow(0px 8px 12px rgba($black, 0.2));
            width: 240px;
            padding: 12px;
            margin-left: auto;
            height: 100%;
            .back {
                color: $secondary;
                font-weight: 600;
                display: inline-flex;
                align-items: center;
                padding: 4px 12px 4px 0px;
                svg{
                    height: 19px;
                    width: 20px;
                }
            }
            h5 {
                text-align: right;
                margin: 12px 0px;
                font-weight: 700;
                color: $secondary;   
            }
            ul {
                li {
                    text-align: right;
                    margin: 4px 0px;
                    a,
                    button {
                        @include fontSize(14px);
                        color: $black;
                        font-weight: 500;
                        padding: 4px 0px;
                        display: inline-block;
                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}